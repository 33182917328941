import { createUseStyles } from 'react-jss';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import ImageClick from '../components/images/ImageClick';
import ImageEmail from '../components/images/ImageEmail';
import ImagePhone from '../components/images/ImagePhone';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(theme => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contactEmailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactInput: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    margin: '1rem',
    padding: '1rem 0.8rem',
    transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, margin 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&:focus': {
      borderColor: theme.colorPrimary,
      borderWidth: '2px',
      margin: '0.8rem',
      outline: '0',
    },
  },
  contactSendContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  featuresContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  featureCardContent: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.5',
  },
  featureIcon: {
    alignSelf: 'center',
    height: '100px',
    width: '100px',
  },
  header: {
    textAlign: 'center',
  },
}));

const styleFeatureCard = {
  display: 'flex',
  flex: '1 1 320px',
  flexDirection: 'column',
  minWidth: '320px',
  width: '40%',
};

const Contact = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Contact" />
      <h1 className={classes.header}>
        Contact
      </h1>
      <div className={classes.main}>
        <div className={classes.featuresContainer}>
          <Card style={styleFeatureCard}>
            <ImageEmail className={classes.featureIcon} />
            <h1 className={classes.header}>
              <a href="mailto:info@kountertop.com">
                info@kountertop.com
              </a>
            </h1>
            <div className={classes.featureCardContent}>
              <div>
                Shoot us an email and we&apos;ll get back to you immediately
              </div>
            </div>
          </Card>
          <Card style={styleFeatureCard}>
            <ImagePhone className={classes.featureIcon} />
            <h1 className={classes.header}>
              <a href="tel:+16509004499">
                +1 (650) 900 - 4499
              </a>
            </h1>
            <div className={classes.featureCardContent}>
              <ul>
                <li>
                  Monday - Friday: 9am - 6pm pacific
                </li>
                <li>
                  Saturday: 10am - 5pm pacific
                </li>
              </ul>
            </div>
          </Card>
        </div>
        <Card style={styleFeatureCard}>
          <ImageClick className={classes.featureIcon} />
          <h1 className={classes.header}>
            Contact Form
          </h1>
          <form action="/api_public/sendContactForm" method="post">
            <div className={classes.contactEmailContainer}>
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                className={classes.contactInput}
                name="email"
                placeholder="Your email"
                type="email"
              />
              <input
                className={classes.contactInput}
                name="name"
                placeholder="Your name"
                type="text"
              />
              <textarea
                className={classes.contactInput}
                name="notes"
                placeholder="Notes"
              />
            </div>
            <div
              className={classes.contactSendContainer}
            >
              <Button type="submit">
                SEND
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Layout>
  );
};

export default Contact;
